import State from '@/app/types/state';
import { createSelector } from 'reselect';

const modalDataSelector = (state: State) => state.chats.modals.data!;

export default createSelector([modalDataSelector], (data) => ({
  files_ids: data?.files,
  user: data?.user,
  time: data?.time,
}));
