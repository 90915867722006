import Grid from '@mui/material/Grid';
import { TOP_MENU_HEIGHT } from '@/app/styles/consts';
import ScreenLayout from '@/components/ScreenLayout';
import SocketLayout from '@/components/SocketLayout';
import React from 'react';
import ChatsChannel from '../ChatsChannel';
import ChatsList from '../ChatsList';
import ChatsModals from '../Modals';

const ChatsRoot = () => {
  return (
    <SocketLayout>
      <ScreenLayout direction="row" columnGap={3} sx={{ minHeight: `calc(100vh - ${TOP_MENU_HEIGHT}px)` }}>
        <ChatsList />

        <Grid item xs={12} md>
          <ChatsChannel />
        </Grid>
      </ScreenLayout>

      <ChatsModals />
    </SocketLayout>
  );
};

export default ChatsRoot;
