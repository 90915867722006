import { IChannelUser } from '@/pages/chats/types';

export default class ChatImageCarouselModalData {
  files: string[] | undefined;
  user: IChannelUser | undefined;
  time: number;

  constructor(files: string[] | undefined, user: IChannelUser | undefined, time: number) {
    this.files = files;
    this.user = user;
    this.time = time;
  }
}
