import { Route } from '@core-ui/redux-router';
import { ROUTES } from '@/app/consts/routes';
import State from '@/app/types/state';
import { getEmployeeDict } from '@/dictionary/actions';
import { getChatsPostsList, getChatsUsersList } from './ChatsChannel/actions';
import { getChatsList } from './ChatsList/actions';
import ChatsRoot from './ChatsRoot';

const key: keyof State = 'chats';

const routes: Route[] = [
  {
    key,
    path: `/:boatId([0-9]+)/:route(${ROUTES.CHATS})`,
    exact: true,
    component: ChatsRoot,
    effects: [{ events: ['on_enter'], trigger: getChatsList }],
  },
  {
    key,
    path: `/:boatId([0-9]+)/:route(${ROUTES.CHATS})/:chatId`,
    exact: true,
    component: ChatsRoot,
    effects: [
      { events: ['on_enter'], trigger: getChatsList },
      { events: ['on_enter'], trigger: getChatsPostsList },
      { events: ['on_enter'], trigger: getChatsUsersList },
      { events: ['on_enter'], trigger: getEmployeeDict },
    ],
  },
];

export default routes;
