import { UnknownUploadIconOcean } from '@core-ui/icons';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GET } from '@/api/chatApi';
import { formatTime } from '@/app/utils/dates';
import { useAppSelector } from '@/hooks/useAppSelector';
import { openChatModal } from '@/pages/chats/Modals/actions';
import { ChatImageCarouselModalData } from '@/pages/chats/Modals/command';
import { IChannelPost } from '@/pages/chats/types';
import { Image } from 'mui-image';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  post: IChannelPost;
}

const noThumbnail = '400';

const UserMessage: React.FC<IProps> = (props) => {
  const { post } = props;
  const [files, setFiles] = useState<string[]>([]);
  const memoizedSelector = useMemo(selector, []);
  const { user } = useAppSelector((state) => memoizedSelector(state, { id: post.user_id }));
  const dispatch = useDispatch();

  const openImageModal = () =>
    dispatch(openChatModal(new ChatImageCarouselModalData(post.file_ids, user, post.update_at)));

  useEffect(() => {
    const getThumbnail = async (file_id: string) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        // @ts-ignore
        setFiles((f) => [...f, e.target.result]);
      };
      try {
        const res = (await GET(`/api/v4/files/${file_id}/thumbnail`, {}, { responseType: 'blob' })) as Blob;
        reader.readAsDataURL(res);
      } catch (e) {
        // TODO fix this stupid thing
        // message: "Файл не имеет миниатюры."
        setFiles((f) => [...f, noThumbnail]);
      }
    };
    post.file_ids?.map(getThumbnail);
  }, []);

  return (
    <Grid container direction="column">
      <Grid container columnGap={1} wrap="nowrap" alignItems="center">
        <Avatar variant="rounded" alt={post.id} />
        <Typography variant="h6" component="span">
          {user?.first_name || user?.last_name}
        </Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{
            marginLeft: '10px',
            fontFeatureSettings: "'ss02' on, 'liga' off",
          }}
        >
          {formatTime(post.update_at, { dateFormat: 'd MMM yyyy' })}
        </Typography>
      </Grid>
      <Typography variant="body1" marginLeft="48px">
        {post.message}
      </Typography>
      {!!post.file_ids?.length && (
        <Grid container columnGap={1} marginLeft="48px" onClick={openImageModal} minHeight="40px">
          {files.map((f, idx) =>
            f === noThumbnail ? (
              <UnknownUploadIconOcean key={idx} />
            ) : (
              <Image key={idx} src={f} height="40px" width="40px" fit="fill" style={{ borderRadius: '4px' }} />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default UserMessage;
