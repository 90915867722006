import { combineReducers } from 'redux';
import modals, { IModalsState } from '@/pages/chats/Modals/reducer';
import {
  IChatsPostsListState,
  IChatsUsersListState,
  postsHandleActions as posts,
  usersHandleActions as users,
} from './ChatsChannel/reducer';
import list, { IChatsListState } from './ChatsList/reducer';

export interface IChatsState {
  list: IChatsListState;
  posts: IChatsPostsListState;
  users: IChatsUsersListState;
  modals: IModalsState;
}

export default combineReducers({
  list,
  posts,
  users,
  modals,
});
