import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { ChatImageCarouselModalData } from '@/pages/chats/Modals/command';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export type IModalsData = ChatImageCarouselModalData;

export interface IModalsState {
  opened: boolean;
  data: Nullable<IModalsData>;
}

const defaultState = Immutable<IModalsState>({
  opened: false,
  data: null,
});

export default handleActions<typeof defaultState>({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
