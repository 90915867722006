import Grid from '@mui/material/Grid';
import UploadedFileRow from '@/components/UploadedFileRow';

interface IProps {
  files: File[];
  removeFile: (file: File) => void;
}
const ChannelInputFiles: React.FC<IProps> = (props) => {
  const { files, removeFile } = props;

  return (
    <Grid container columnGap={2} marginBottom="12px">
      {files.map((file, idx) => (
        <UploadedFileRow key={idx} file={file} removeFile={() => removeFile(file)} />
      ))}
    </Grid>
  );
};

export default ChannelInputFiles;
